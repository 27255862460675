$( "#go-service-1" ).click(function() {showService(1)});
$( "#go-service-2" ).click(function() {showService(2)});
$( "#go-service-3" ).click(function() {showService(3)});
$( "#go-service-4" ).click(function() {showService(4)});
$( "#go-service-5" ).click(function() {showService(5)});
//$( "#go-service-6" ).click(function() {showService(6)});

$( "#see-service-1" ).click(function() {
    showService(1);
    //alert("asdd11   ");
    //history.pushState(null, "", "mengano.html");

    

});
$( "#see-service-2" ).click(function() {showService(2)});
$( "#see-service-3" ).click(function() {showService(3)});
$( "#see-service-4" ).click(function() {showService(4)});
$( "#see-service-5" ).click(function() {showService(5)});
//$( "#see-service-6" ).click(function() {showService(6)});

function showService(id) {

    $( "#service_0" ).fadeOut(200, function() {
        $('#service_'+id).fadeIn(400);
    });

}

$( "#back-services-1" ).click(function() {backServices(1)});
$( "#back-services-2" ).click(function() {backServices(2)});
$( "#back-services-3" ).click(function() {backServices(3)});
$( "#back-services-4" ).click(function() {backServices(4)});
$( "#back-services-5" ).click(function() {backServices(5)});
//$( "#back-services-6" ).click(function() {backServices(6)});

function backServices(id){


    $('#service_'+id).fadeOut(200, function() {
        $('#service_0').fadeIn(400);
        history.pushState(null, "", "/");

    });

}

$('#serviceIcon12').click(function() {changeServices(1,2)});
$('#serviceIcon13').click(function() {changeServices(1,3)});
$('#serviceIcon14').click(function() {changeServices(1,4)});
$('#serviceIcon15').click(function() {changeServices(1,5)});
//$('#serviceIcon16').click(function() {changeServices(1,6)});

$('#serviceIcon21').click(function() {changeServices(2,1)});
$('#serviceIcon23').click(function() {changeServices(2,3)});
$('#serviceIcon24').click(function() {changeServices(2,4)});
$('#serviceIcon25').click(function() {changeServices(2,5)});
//$('#serviceIcon26').click(function() {changeServices(2,6)});

$('#serviceIcon31').click(function() {changeServices(3,1)});
$('#serviceIcon32').click(function() {changeServices(3,2)});
$('#serviceIcon34').click(function() {changeServices(3,4)});
$('#serviceIcon35').click(function() {changeServices(3,5)});
//$('#serviceIcon36').click(function() {changeServices(3,6)});


$('#serviceIcon41').click(function() {changeServices(4,1)});
$('#serviceIcon42').click(function() {changeServices(4,2)});
$('#serviceIcon43').click(function() {changeServices(4,3)});
$('#serviceIcon45').click(function() {changeServices(4,5)});
//$('#serviceIcon46').click(function() {changeServices(4,6)});


$('#serviceIcon51').click(function() {changeServices(5,1)});
$('#serviceIcon52').click(function() {changeServices(5,2)});
$('#serviceIcon53').click(function() {changeServices(5,3)});
$('#serviceIcon54').click(function() {changeServices(5,4)});
//$('#serviceIcon56').click(function() {changeServices(5,6)});


$('#serviceIcon61').click(function() {changeServices(6,1)});
$('#serviceIcon62').click(function() {changeServices(6,2)});
$('#serviceIcon63').click(function() {changeServices(6,3)});
$('#serviceIcon64').click(function() {changeServices(6,4)});
//$('#serviceIcon65').click(function() {changeServices(6,5)});

$('#pyme_innovation').click(function(){
    window.open('https://sede.micinn.gob.es/pyiINFO/buscarPyi.mec?&nif=B63759658', '_blank');
});

$('#iso_9001').click(function(){
    window.open('https://coresolutions.es/assets/docs/coresolutions-inventarios-iso-92018.pdf', '_blank');
});
